import React from 'react'
import SubHero from '../components/SubHero'
import PropTypes from 'prop-types'
import Layout from '../components/layout'

import Button from '../components/Button'
import Helmet from 'react-helmet'

class Services extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Services | NEXTGEN Code Company"
          meta={[
            {
              name: 'description',
              content:
                "Website's, SEO, search engine optimization, mobile app's, web applications, UI UX design, and more.",
            },
          ]}
        />

        <SubHero title="Services" subtitle="" />
        <section className="section">
          <div className="container">
            <h1>You can hire us as...</h1>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="ng-services-option-card column">
                <div className="ng-services-option-content">
                  <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557522577/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/Blue_Pill.jpg" />
                  <h2>An extension of your team</h2>
                  <div className="divider" />
                  <p>
                    This is the best way for us to truly improve business. When
                    we are on retainer, we are able to fully evaluate your pain
                    points and solve them with macro and micro level solutions.
                    See{' '}
                    <a className="inline-link" href="/mr-pink-story">
                      how we work (our process)
                    </a>
                  </p>
                  <Button
                    to="/extension-of-your-team"
                    color="dark"
                    text="Learn More"
                  />
                </div>
              </div>
              <div className="column">
                <h2 style={{ textAlign: 'center' }}>OR</h2>
              </div>

              <div className="ng-services-option-card column">
                <div className="ng-services-option-content">
                  <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557522046/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/Red_Pill.jpg" />
                  <h2>For a specific task</h2>
                  <div className="divider" />
                  <p>
                    If you already know what you want, perfect! We can help. Our
                    services <b>are not limited</b> to this list. These are just
                    our most common ones.
                  </p>

                  <Button
                    to="/individual-services"
                    color="dark"
                    text="Learn More"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <h3>To find out more about these, reach out to us.</h3>
            <h1>Don't be shy!</h1>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Services
